import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import find from 'lodash/find';
import Head from 'components/head';
import UWAudioPlayer from '../../components/UWAudioPlayer/UWAudioPlayerHowler';

import { QuickInfo } from './_episode_player.css';

const getEpisodeFromSlug = (episode, slug, data) => {
  const podcast = find(data.podcasts, { slug: slug });
  const relEpisode = find(podcast.episodes, { slug: episode.slug });
  return { episode: relEpisode, podcast: podcast };
};

const EpisodePlayer = ({ data, pageContext }) => {
  let played = false;
  const { episode, slug } = pageContext;
  const episodeFromSlug = getEpisodeFromSlug(
    episode,
    slug,
    data.productionsJson
  );

  const pushToDataLayer = episode => {
    if (window && window.dataLayer && !played) {
      played = true;
      window.dataLayer.push({
        AudioEvent: 'episodePlayer',
        other: 'keder_du_dig_01',
      });
    }
  };

  if (episode) {
    return (
      <>
        <Head pageTitle={episode.title} />
        <UWAudioPlayer
          audioList={{ episodes: [episodeFromSlug.episode] }}
          takeFirst={true}
          single={true}
          onClick={pushToDataLayer}
        />
        <QuickInfo
          dangerouslySetInnerHTML={{
            __html: episodeFromSlug.podcast.longCopy.childMarkdownRemark.html,
          }}
        />
      </>
    );
  } else {
    return <span>an error occured rendering the list</span>;
  }
};

EpisodePlayer.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

EpisodePlayer.defaultProps = {
  data: { empty: 'object' },
  pageContext: { slug: 'object', episode: { title: 'no title' } },
};

export default EpisodePlayer;

export const query = graphql`
  query episodeQuery {
    productionsJson {
      title
      podCastHeader
      podcasts {
        title
        slug
        copy
        longCopy {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        imageSocial {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        status
        link
        episodes {
          title
          slug
          image {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          imageSocial {
            childImageSharp {
              fluid(maxHeight: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          releaseDate
          status
          URI
        }
      }
    }
  }
`;
